.f-400 { font-weight: var(--f-400); }
.f-500 { font-weight: var(--f-500); }
.f-600 { font-weight: var(--f-600); }
.f-700 { font-weight: var(--f-700); }

.fs-12 { font-size: var(--font-size-12); }
.fs-13 { font-size: var(--font-size-13); }
.fs-14 { font-size: var(--font-size-14); }
.fs-16 { font-size: var(--font-size-16); }
.fs-18 { font-size: var(--font-size-18); }
.fs-21 { font-size: var(--font-size-21); }
.fs-24 { font-size: var(--font-size-24); }
.fs-30 { font-size: var(--font-size-30); }

.lh-1 { line-height: 1; }
.lh-1-1 { line-height: 1.1; }
.lh-1-2 { line-height: 1.2; }

.f-heading { font-family: var(--f-heading); }